import moment from "moment";
//import { Bar } from "react-chartjs-2";

import * as firebase from "firebase/app";

import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonButton
} from "@ionic/react";
import "./Tab1.css";

import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonChip
} from "@ionic/react";
import {
  sunny,
  thermometer,
  water,
  colorFill,
  bonfire,
  add
} from "ionicons/icons";

const raisedBedImageUrl =
  "https://i.nctodo.com/img/gardening-how-to/zone-6-vegetable-planting-tips-on-growing-vegetables-in-zone-6.jpg";
const greenhouseImageUrl =
  "https://www.veseys.com/media/catalog/product/cache/image/700x700/e9c3970ab036de70892d86c6d221abfe/8/9/89109.jpg";

const firebaseConfig = {
  apiKey: "AIzaSyDCxYfdkn0bK7lOgWCbF0qE-YoRhup7J-U",
  authDomain: "gardenxp11.firebaseapp.com",
  databaseURL: "https://gardenxp11.firebaseio.com",
  projectId: "gardenxp11",
  storageBucket: "gardenxp11.appspot.com",
  messagingSenderId: "960332324979",
  appId: "1:960332324979:web:34840f30b724cc97188a06",
  measurementId: "G-3MTNGVHMSE"
};
firebase.initializeApp(firebaseConfig);

const Tab1: React.FC = () => {
  const [gardenData, setGardenData] = useState<any>(null);

  const [sensorData] = useState<any>({});

  useEffect(() => {
    async function loadsData() {
      await loadSensorData();
    }

    async function loadGData() {
      await loadGardenData();
    }

    setInterval(loadsData, 10000);
    setInterval(loadGData, 10000);

    loadsData(); //first time
    loadGData(); //first time
  }, []);

  const loadGardenData = () => {
    console.log("retrieve from firebase");

    firebase
      .database()
      .ref("/tenant/505f0d5e-85be-45bf-a6c6-c1caadf202c5/garden/")
      .once("value")
      .then(function(snapshot) {
        setGardenData(snapshot.val());
      });
  };

  // fetch(
  //   "https://gardenxp11.firebaseio.com/tenant/505f0d5e-85be-45bf-a6c6-c1caadf202c5/garden.json"
  // )
  //   .then(resp => resp.json()) // Transform the data into json
  //   .then(function (data) {
  //     setGardenData(data);
  //   });

  const getTemperature = (sensordata: any, area: any) => {
    if (area == null) return null;

    if (area.environment == null) return null;

    if (sensordata[area.environment.temperature] == null) return null;

    let data = sensordata[area.environment.temperature].current;

    let timespan = moment(new Date(data.timeStamp)).fromNow();

    return `${data.temperature} (${timespan})`;
  };

  // const data = {
  //   labels: ["January", "February", "March", "April", "May", "June", "July"],
  //   datasets: [
  //     {
  //       label: "My First dataset",
  //       backgroundColor: "rgba(255,99,132,0.2)",
  //       borderColor: "rgba(255,99,132,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [65, 59, 80, 81, 56, 55, 40]
  //     }
  //   ]
  // };

  const loadSensorData = () => {};

  // fetch(
  //   "https://gardenxp11.firebaseio.com/tenant/505f0d5e-85be-45bf-a6c6-c1caadf202c5/devicedata.json"
  // )
  //   .then(resp => resp.json()) // Transform the data into json
  //   .then(function(data) {
  //     setSensorData(data);
  //   });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Areas</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Areas</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonButton>
            <IonIcon icon={add} />
            <IonLabel>Add area</IonLabel>
          </IonButton>
        </IonFab>

        {/* <Bar
          data={data}
          width={100}
          height={50}
          options={{
            maintainAspectRatio: false
          }}
        /> */}

        {gardenData === null
          ? null
          : Object.keys(gardenData.areas).map(key =>
              <IonCard key={key} routerLink={`/tab1/crops/${key}`}>
                <img
                  alt={"meaningfultext"}
                  src={
                    gardenData.areas[key].areaType === "greenhouse"
                      ? greenhouseImageUrl
                      : raisedBedImageUrl
                  }
                />
                <IonCardHeader>
                  <IonCardSubtitle>Sted</IonCardSubtitle>
                  <IonCardTitle>
                    {gardenData.areas[key].name}
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  Keep close to Nature's heart... and break clear away, once in
                  awhile, and climb a mountain or spend a week in the woods.
                  Wash your spirit clean.
                  <br />
                  <br />
                  {(gardenData.areas[key].crops || [])
                    .map((cropId: React.ReactText, index: number) =>
                      <IonChip key={index} outline={true} color="success">
                        <IonLabel>
                          {(gardenData.crops[cropId] || {}).name}
                        </IonLabel>
                      </IonChip>
                    )}
                </IonCardContent>
                <IonItem>
                  <IonIcon icon={thermometer} slot="start" />
                  <IonLabel color="danger">
                    {getTemperature(sensorData, gardenData.areas[key])}
                  </IonLabel>
                  <IonIcon color="warning" icon={bonfire} slot="end" />
                </IonItem>
                <IonItem>
                  <IonIcon icon={sunny} slot="start" />
                  <IonLabel>950 lm</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon icon={water} slot="start" />
                  <IonChip outline={true} color="success">
                    <IonLabel>Tomat 1</IonLabel>
                  </IonChip>
                  <IonLabel>60%</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon icon={water} slot="start" />
                  <IonChip outline={true} color="success">
                    <IonLabel>Tomat 2</IonLabel>
                  </IonChip>
                  <IonLabel>69%</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon icon={water} slot="start" />
                  <IonChip outline={true} color="danger">
                    <IonLabel>Agurk</IonLabel>
                  </IonChip>
                  <IonLabel>20%</IonLabel>
                  <IonIcon color="primary" icon={colorFill} slot="end" />
                </IonItem>
                <IonItem>
                  <IonIcon icon={water} slot="start" />
                  <IonChip outline={true} color="success">
                    <IonLabel>Squash</IonLabel>
                  </IonChip>
                  <IonLabel>40%</IonLabel>
                </IonItem>
              </IonCard>
            )}
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
