export interface addCropAndAllocateCommand {
  cropId: string;
  areaId: string;
  newCropName: string;
  newCropPlantType: string;
  newCropistForArea: string[];
}

export const addCropsAndAllocate = async (
  command: addCropAndAllocateCommand
) => {
  await fetch(
    `https://us-central1-gardenxp11.cloudfunctions.net/cropAddOrUpdate/${command.cropId}?key=CRZbsYJ3ZPDvXCL6oq7IPEAfFG8KIG80Yx6w8gK5L`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: command.newCropName,
        plantType: command.newCropPlantType
      })
    }
  );

  let setAreaCropsResponse = await fetch(
    `https://us-central1-gardenxp11.cloudfunctions.net/areaSetCrops/${command.areaId}?key=CRZbsYJ3ZPDvXCL6oq7IPEAfFG8KIG80Yx6w8gK5L`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        crops: command.newCropistForArea
      })
    }
  );

  let data = await setAreaCropsResponse.json();

  return data;
};
