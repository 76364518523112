import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton
} from "@ionic/react";
import "./Tab3.css";
import firebase from "firebase";

const LogIn: React.FC = () => {
  const logIn = () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(function(result: any) {
        // This gives you a Google Access Token. You can use it to access the Google API.

        //var token = result.credential.accessToken;

        // The signed-in user info.

        //var user = result.user;

        console.log("success. user:" + result.user);
        // ...
      })
      .catch(function(error) {
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        console.log(error.message);
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        // ...
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Log in</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Log in</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonButton onClick={() => logIn()}>
          Log in with Google Account
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default LogIn;
