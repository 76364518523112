import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonCardTitle,
  IonChip,
  IonIcon,
  IonItem,
  IonLabel
} from "@ionic/react";
import { Doughnut, Line } from "react-chartjs-2";

import "./Tab3.css";
import firebase from "firebase";
import {
  bonfire,
  colorFill,
  key,
  sunny,
  thermometer,
  water
} from "ionicons/icons";

var randomMoisture = function() {
  return 70 + Math.round(Math.random() * 30);
};

var randomWatering = function() {
  return 30 + Math.round(Math.random() * 240);
};

var randomScalingFactor = function() {
  return Math.round(Math.random() * 100);
};

var options = {
  title: {
    display: true,
    text: "Chart.js Line Chart - Multi Axis"
  },
  type: "line",
  scales: {
    yAxes: [
      {
        id: "y",
        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: "left",
        ticks: {
          suggestedMin: 50,
          suggestedMax: 100
        },
        scaleLabel: {
          display: true,
          labelString: "Soil Moisture %",
          fontSize: 25
        }
      },
      {
        id: "y1",
        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: "right",
        ticks: {
          suggestedMin: 0,
          suggestedMax: 500
        },
        scaleLabel: {
          display: true,
          labelString: "Minutes watering",
          fontSize: 25
        }
      }
    ]
  }
};

var data = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      type: "line",
      label: "Moisture 1",
      borderColor: "#FF6384",
      borderWidth: 2,
      fill: false,
      data: [
        randomMoisture(),
        randomMoisture(),
        randomMoisture(),
        randomMoisture(),
        randomMoisture(),
        randomMoisture(),
        randomMoisture()
      ],
      yAxisID: "y"
    },
    {
      type: "line",
      label: "Moisture 2",
      borderColor: "#36A2EB",
      borderWidth: 2,
      fill: false,
      data: [
        randomMoisture(),
        randomMoisture(),
        randomMoisture(),
        randomMoisture(),
        randomMoisture(),
        randomMoisture(),
        randomMoisture()
      ],
      yAxisID: "y"
    },
    {
      type: "bar",
      label: "Watering 1",
      backgroundColor: "#FFCE56",
      data: [
        randomWatering(),
        randomWatering(),
        randomWatering(),
        randomWatering(),
        randomWatering(),
        randomWatering(),
        randomWatering()
      ],
      yAxisID: "y1",
      borderColor: "white",
      borderWidth: 2
    },
    {
      type: "bar",
      label: "Watering 2",
      backgroundColor: "green",
      data: [
        randomWatering(),
        randomWatering(),
        randomWatering(),
        randomWatering(),
        randomWatering(),
        randomWatering(),
        randomWatering()
      ],
      yAxisID: "y1"
    }
  ]
};

const Tab3: React.FC = () => {
  const createUser = () => {
    console.log("hepp");
    firebase
      .auth()
      .createUserWithEmailAndPassword("trond@itrond.net", "hemmelig")
      .catch(function(error) {
        alert(error.message);
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        // ...
      });
  };

  const logOn = () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(function(result: any) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        //var user = result.user;

        console.log("success. user:" + result.user);
        // ...
      })
      .catch(function(error) {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        console.log(error.message);
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });

    // firebase.auth().signInWithEmailAndPassword("trond@itrond.net", "tiur2tiur").catch(function (error) {
    //   // Handle Errors here.
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // ...
    // });

    // let user = firebase.auth().currentUser;

    // if (user) {
    //   console.log(user.email);
    // } else {
    //   // No user is signed in.
    // }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Devices</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Devices</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        {/* <IonButton onClick={() => logOn()}>log ON</IonButton>
        <IonButton onClick={() => createUser()}>create user</IonButton> */}

        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Device 1</IonCardSubtitle>
            <IonCardTitle>
              <span>tittl</span>
            </IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Keep close to Nature's heart... and break clear away, once in
            awhile, and climb a mountain or spend a week in the woods. Wash your
            spirit clean.
            <br />
            <br />
            {/* <Doughnut data={data} /> */}
            <Line data={data} options={options} />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
