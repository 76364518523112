/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  IonList,
  IonItem,
  IonInput,
  IonFabButton,
  IonFab,
  IonModal,
  IonButton
} from "@ionic/react";
import "./Tab2.css";
import { RouteComponentProps } from "react-router";
import { add } from "ionicons/icons";
import { addCropsAndAllocate } from "../business/commands/Commands";
import { Guid } from "../business/utils/Guid";

interface AreaPageProps
  extends RouteComponentProps<{
      areaId: string;
    }> {}

const Area: React.FC<AreaPageProps> = ({ match }) => {
  const [gardenData, setGardenData] = useState<any>(null);

  const [uiState, setUiState] = useState({ showAddCrop: false });

  const [newCropName, setNewCropName] = useState<any>(null);
  const [newCropPlantType, setNewCropPlantType] = useState<any>(null);

  useEffect(() => {
    async function loadGData() {
      await load();
    }

    loadGData(); //first time
  }, []);

  const load = () =>
    fetch(
      "https://gardenxp11.firebaseio.com/tenant/505f0d5e-85be-45bf-a6c6-c1caadf202c5/garden.json"
    )
      .then(resp => resp.json()) // Transform the data into json
      .then(function(data) {
        setGardenData(data);
      });

  const area = () =>
    gardenData == null ? {} : gardenData.areas[match.params.areaId];
  const areaId = (): string => match.params.areaId;

  const addCropToArea = () => {
    const cropId = Guid.newGuid();

    addCropsAndAllocate({
      areaId: areaId(),
      cropId: cropId,
      newCropName: newCropName,
      newCropPlantType: newCropPlantType,
      newCropistForArea:
        area().crops == null ? [cropId] : [...area().crops, cropId]
    }).then(() => {
      setUiState({ showAddCrop: false });
      load();
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tab1" />
          </IonButtons>
          <IonTitle>
            Area {gardenData == null ? null : area().name}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tabddddddddddddddddddd 2</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonSegment
          onIonChange={e => console.log("Segment selected", e.detail.value)}
          value="cropList"
        >
          <IonSegmentButton value="cropList">
            <IonLabel>🌾Crops</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="environment">
            <IonLabel>📈Environment</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="pictures">
            <IonLabel> 📸 Pictures </IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <IonList>
          {gardenData == null
            ? null
            : (area().crops || []).map((i: string) =>
                <IonItem>
                  {/* <IonAvatar slot="start">
                                <IonIcon icon={leafOutline}></IonIcon>
                            </IonAvatar> */}
                  <IonLabel>
                    //eslint-disable-next-line
                    <h2>{gardenData.crops[i].name}</h2>
                    <h3>Dyrket 3 uker siden</h3>
                    <p>Forventet høsting om 2 uker</p>
                  </IonLabel>
                  {/* <IonAvatar slot="end">
                                <IonIcon icon={camera}></IonIcon>
                            </IonAvatar> */}
                </IonItem>
              )}
        </IonList>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => setUiState({ showAddCrop: true })}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>

        <IonModal
          isOpen={uiState.showAddCrop}
          onDidDismiss={() => setUiState({ showAddCrop: false })}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle size="large">
                What's your new crop in {area().name}?
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setUiState({ showAddCrop: false })}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">Crop name</IonLabel>
                <IonInput
                  value={newCropName}
                  placeholder="name the crop. Eg. Tomato small"
                  onIonChange={e => setNewCropName(e.detail.value!)}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Plant type</IonLabel>
                <IonInput
                  value={newCropPlantType}
                  placeholder="Enter Input"
                  onIonChange={e => setNewCropPlantType(e.detail.value!)}
                />
              </IonItem>
              <IonButton onClick={() => addCropToArea()}>
                Add Crop to {area().name}
              </IonButton>
            </IonList>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Area;
